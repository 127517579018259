.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
}

.react-datepicker__input-container input:focus {
  box-shadow: none;
  border: 2px solid var(--color-secondary);
}

.react-datepicker__input-container input {
  font-size: 1em;
  width: 100%;
  height: 36px;
  border: 1px solid gainsboro;
  border-radius: 5px;
}

