.icon-container {
  fill: rgba(156, 163, 175, 1);
}
.icon-container:hover {
  fill: white;
}
.icon-style {
  width: 15px;
  height: 15px;
  fill: inherit
}

.icon-style:hover {
  fill: inherit
}